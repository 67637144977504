.custom-accordion.MuiAccordion-root {
  box-shadow: none;
  background-color: transparent;
}

.custom-accordion.MuiAccordion-root > div {
  padding: 0;
}

.custom-accordion.MuiAccordion-root h6 {
  font-family: 'Proxima Nova Rg';
  font-size: 20px;
  font-weight: 600;
}

.custom-accordion::before.MuiAccordion-root {
  display: none;
}
